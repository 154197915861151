import React from 'react'
import { graphql } from 'gatsby'
import BlockHero from 'components/blocks/BlockHero'
import BlockCards from 'components/blocks/BlockCards'
import BlockBgImagePoints from 'components/blocks/BlockBgImagePoints'
import BlockContact from 'components/blocks/BlockContact'
import Footer from 'components/Footer'
import Seo from 'components/Seo'

// markup
const Page = ({ data }) => {
  const merchants = data.allPrismicMerchant.nodes
  const content = {
    blockHero: {
      image: `DSC_5872-flip.jpg`,
      textBlock: {
        hasContactButton: true,
        buttonAddClass: 'reserve_join',
        text: {
          pretitle: `您有好项目，我们帮您迅速拓店
        您还没项目，来星选加盟 ——`,
          title: '您更靠谱的加盟选择',
          description: `我们为您层层筛选更优质的加盟品牌，将品牌属性与优质点位一一匹配，每个合作品牌都是严格核算单店盈利模型，通过“点位数据+品牌属性”的匹配原则，给每个加入餐饮行业的商户提供靠谱选择。为您的长远发展保驾护航。`,
        },
      },
    },
    cards: {
      title: '星选品牌',
      cards: merchants.map((merchant, i) => {
        return {
          link: `/merchants/${merchant.uid}/`,
          gatsbyImage: merchant.data.image,
          text: {
            // pretitle: '品牌页面',
            title: merchant.data.title.text,
            excerpt: merchant.data.excerpt.text,
          },
          addClass: `join_${i + 1}`,
        }
      }),
    },
    bgImagePoints: {
      image: 'image--kitchens-54e0802e4260a066f03ab176470a0b4c.jpg',
      text: {
        section: '加盟流程',
        points: [
          {
            number: '01',
            pretitle: '初步需求',
            body: '<p>了解意向城市和业务方向，根据意向与预算匹配品牌。</p>',
          },
          {
            number: '02',
            pretitle: '考察品牌',
            body: '<p>专业团队考察品牌经营情况、公司实力、菜品及试菜，评估品牌运营支持能力。</p>',
          },
          {
            number: '03',
            pretitle: '匹配区域',
            body: '<p>确认品牌与区域完美结合的点位进行落铺流程。</p>',
          },
          {
            number: '04',
            pretitle: '签订合同',
            body: '<p>专业团队搞定全部租务，在线电子合同，省去繁杂环节。</p>',
          },
          {
            number: '05',
            pretitle: '店面装修',
            body: '<p>协助商户设备入场、水电接驳等入驻流程，提供场地维护及安全培训。</p>',
          },
          {
            number: '06',
            pretitle: '店铺上线',
            body: '<p>提供新商户入驻服务包，专业建店服务，提供全面培训。</p>',
          },
        ],
      },
    },
  }
  const seoContent = {
    title: `星选加盟`,
  }

  return (
    <div>
      <Seo content={seoContent} />
      <BlockHero
        content={content.blockHero}
        mobileBackgroundClass='solid-grey'
      />
      <BlockCards content={content.cards} />
      <BlockBgImagePoints content={content.bgImagePoints} />
      <BlockContact phoneLabel='加盟咨询热线' buttonAddClass='reserve_bottom' />
      <Footer />
    </div>
  )
}

export default Page

export const query = graphql`
  query {
    allPrismicMerchant(sort: { order: DESC, fields: last_publication_date }) {
      nodes {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
          image {
            gatsbyImageData
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
